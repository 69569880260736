import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { apiRequest } from "../utils/api";
import { getDate } from "../utils/date";
import Swal from "sweetalert2";

function Orders() {
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [applyStatus, setApplyStatus] = useState("");
  const [search, setSearch] = useState("");
  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => <>{index + 1}</>,
    },
    {
      name: "Reg Number",
      selector: (row) => (
        <>
          <button type="button" className="btn btn-warning yello-number">
            {row.order_fullnumber}
          </button>
        </>
      ),
    },

    {
      name: "Price",
      selector: (row) => (
        <>
          {" "}
          <p>£{row.order_total}</p>
        </>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {" "}
          <p
            className={
              row?.order_status === "completed"
                ? "text-success"
                : row?.order_status === "pending"
                ? "text-warning"
                : "text-danger"
            }
          >
            {row.order_status}
          </p>
        </>
      ),
    },

    {
      name: "Order Date",
      selector: (row) => (
        <>
          <p>{getDate(row.order_on)} </p>
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          {" "}
          <div className="edit-delet">
            <Link to={`/order/${row.order_by}`}>
              <img src="/img/eye.png" />
            </Link>
            <Link onClick={() => deleteOrder(row?.order_by)}>
              <img src="/img/trash.png" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  async function deleteOrder(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await apiRequest.delete("/customer/update-delete/" + id);
        Swal.fire({
          position: "top-end",
          icon: "info",
          title: "Please Wait For Few Moments",
          showConfirmButton: false,
          timer: 1500,
        });
        if (res.data.status == 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Data Has Been Deleted",
            showConfirmButton: false,
            timer: 1500,
          });
          window?.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      }
    });
  }

  useEffect(() => {
    getOrders();
    document.title = `Orders | JDM Orders`;
  }, []);

  async function getOrders() {
    try {
      let res = await apiRequest(`/order/all`);
      if (res.data.status === 200) {
        setOrders(res.data.message?.filter((o) => o.order_status != "deleted"));
        setSelectedOrders(res.data.message?.filter((o) => o.order_status != "deleted"));
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function applyFilter() {
    let value = applyStatus;

    if (!value || value == "all") {
      setSelectedOrders(orders);
    }
    if (value == "pending") {
      setSelectedOrders(
        orders.filter((p) => p?.order_status?.toLowerCase() == "pending")
      );
    }

    if (value == "completed") {
      setSelectedOrders(
        orders.filter((p) => p?.order_status?.toLowerCase() == "completed")
      );
    }
    if (value == "cancelled") {
      setSelectedOrders(
        orders.filter((p) => p?.order_status?.toLowerCase() == "cancelled")
      );
    }
  }
  return (
    <div>
      <div className="main-content">
        <div className="row">
          <div className="table-title selet ">
            <h2>Orders</h2>
            <div className="search-list">
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => setApplyStatus(e.target.value)}
              >
                <option selected="" value={"all"}>
                  All Orders
                </option>
                <option value={"pending"}>Pending</option>
                <option value={"completed"}>Sold</option>
                <option value={"cancelled"}>Rejected</option>
              </select>
              <button
                type="button"
                onClick={applyFilter}
                className="btn btn-primary"
              >
                Apply
              </button>
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <input
                className="form-control"
                placeholder="Enter Number For Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="datatable0">
              <DataTable
                columns={columns}
                data={
                  search.length > 0
                    ? selectedOrders.filter(
                        (p) =>
                          p?.order_fullnumber
                            ?.toLowerCase()
                            ?.indexOf(search.toLowerCase()) >= 0
                      )
                    : selectedOrders
                }
                pagination
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Orders;
