import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { apiRequest, apiUrl, baseUrl } from "../../utils/api";
import { Link } from "react-router-dom";
import { getDate } from "../../utils/date";
import Swal from "sweetalert2";

function Customers() {
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    getCustomers();
    document.title = `Customers | JDM Plates`;
  }, []);

  async function getCustomers() {
    try {
      let res = await apiRequest.get(`/customer/all`);
      if (res.data.status === 200) {
        let data = res.data.message;
        setCustomers(data.filter((d) => d.status != "deleted"));
      } else {
        setCustomers([]);
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function exportCustomers() {
    try {
      // Make an API call to the server
      const response = await fetch(`${baseUrl}/customer/export`, {
        method: "GET",
      });

      // Check if the response is OK
      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      // Create a Blob from the response data
      const blob = await response.blob();

      // Create a URL for the Blob and trigger a download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "customers.xlsx"; // Set the file name
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url); // Clean up the URL object
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  }

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => <>{index + 1}</>,
    },
    {
      name: "Reg Number",
      selector: (row) => (
        <>
          {row.order_fullnumber ? (
            <button type="button" className="btn btn-warning yello-number">
              {row.order_fullnumber}
            </button>
          ) : (
            <p>No Plate</p>
          )}
        </>
      ),
    },
    {
      name: "Name",
      selector: (row) => (
        <>
          <p>
            {row.firstname} {row.surname}
          </p>
        </>
      ),
    },

    {
      name: "Order Date",
      selector: (row) => (
        <>
          {" "}
          <p>{getDate(row.registered_on)}</p>{" "}
        </>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {row.order_status ? (
            <p>{row.order_status}</p>
          ) : (
            <p className="approved-link">No Order</p>
          )}
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          {" "}
          <div className="edit-delet">
            {row.Action}
            {/* <Link> 
                <img src="img/trash.png" />
              </Link> */}
            <Link to={`/customer/${row.uid}`}>
              <img src="/img/eye.png" />{" "}
            </Link>
            <Link onClick={() => checkPermission(row.uid)}>
              <img src="/img/trash.png" />{" "}
            </Link>
          </div>
        </>
      ),
    },
  ];

  async function checkPermission(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await apiRequest.delete("/customer/update-delete/" + id);
        Swal.fire({
          position: "top-end",
          icon: "info",
          title: "Please Wait For Few Moments",
          showConfirmButton: false,
          timer: 1500,
        });
        if (res.data.status == 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Data Has Been Deleted",
            showConfirmButton: false,
            timer: 1500,
          });
          window?.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      }
    });
  }

  return (
    <div className="main-content">
      <div className="row">
        <div className="table-title selet">
          <h2>Customers</h2>
          <div className="search-list">
            <button
              type="button"
              onClick={exportCustomers}
              className="btn btn-primary"
            >
              Export
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="datatable0">
            <DataTable columns={columns} data={customers} pagination />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customers;
