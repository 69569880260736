import React, { useEffect, useState } from "react";
import { apiRequest } from "../../utils/api";
import { useLocation, useParams } from "react-router-dom";
import { transformStringToJDM } from "../../utils/string";

function Customer() {
  const [customer, setCustomer] = useState();
  const [status, setStatus] = useState("");
  const [buylineDetails, setBuylineDetails] = useState();
  const statuses = ["placed", "pending", "cancelled", "completed"];
  const { id } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    getCustomer();
  }, []);

  async function getCustomer() {
    try {
      let res = await apiRequest(`/customer/single/${id}`);
      if (res.data.status === 200) {
        setCustomer(res.data.message);
        if (res.data.message?.payment_mode == "buyline") {
          getBuylineDetails(res.data.message);
        }
        if (pathname.indexOf("/order") >= 0) {
          document.title = `${res.data.message?.order_fullnumber} | JDM Plates`;
        } else {
          document.title = `${(res.data.message?.firstname).toUpperCase()} | JDM Plates`;
        }
        setStatus(res.data.message?.order_status);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function changeStatus(e) {
    e.preventDefault();
    try {
      let payload = {
        order_status: status,
        order_id: customer.order_id,
        customer,
      };
      let res = await apiRequest.put(`/order/update-status`, payload);

      if (res.data.status === 200) {
        console.log("Updated");
        alert("Status Updated");
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getBuylineDetails(details) {
    try {
      let res = await apiRequest.get(
        `/buyline/details/${transformStringToJDM(details?.order_fullnumber)}/${
          details?.uid
        }`
      );
      if (res.data.status == 200) {
        setBuylineDetails(res.data.message);
      }

      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      {customer && (
        <>
          <div className="table-title">
            <h2>
              {pathname.indexOf("/order") >= 0
                ? "Order Details"
                : "Customer Details"}
            </h2>
          </div>
          <div className="update-form">
            <div className="row">
              <div className="col-md-6">
                <div className="listing-name">
                  <ul>
                    <li>
                      <h3>
                        <span>Name:</span>
                        {customer.firstname} {customer.surname}
                      </h3>
                    </li>
                    <li>
                      <h3>
                        <span>Email:</span>
                        <label style={{ textTransform: "lowercase" }}>
                          {customer.email}
                        </label>
                      </h3>
                    </li>
                    <li>
                      <h3>
                        <span>Phone:</span>
                        {customer.phone}
                      </h3>
                    </li>
                    <li>
                      <h3>
                        <span>Street Address:</span>
                        {customer.address}
                      </h3>
                    </li>
                    <li>
                      <h3>
                        <span>Town:</span>
                        {customer.town}
                      </h3>
                    </li>
                    <li>
                      <h3>
                        <span>County:</span>
                        <label>{customer.county}</label>
                      </h3>
                    </li>
                    <li>
                      <h3>
                        <span>Post Code:</span>
                        {customer.postcode}
                      </h3>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="plate-form">
                  <form onSubmit={changeStatus}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Order Status<sup>*</sup>
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            {statuses.map((s, i) => (
                              <option
                                key={i}
                                selected={s === customer.order_status}
                                value={s}
                                style={{ textTransform: "capitalize" }}
                              >
                                {s}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <button type="submit" className="btn btn-primary">
                          Change Status
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="table-title bottom">
            <h2>Order</h2>
          </div>
          {customer.order_id ? (
            <div className="update-form view-adon">
              <div className="row">
                <div
                  className={
                    customer?.payment_status ? "col-md-6" : "col-md-12"
                  }
                >
                  <div className="listing-name">
                    <ul>
                      {customer.nominee_name ? (
                        <li>
                          <h3>
                            <span>Nominee:</span>
                            {customer.nominee_title}. {customer.nominee_name}{" "}
                            {customer.nominee_surname}
                          </h3>
                        </li>
                      ) : null}
                      <li>
                        <h3>
                          <span>Number:</span>
                          <span className="btn btn-warning yello-number">
                            {customer.order_fullnumber}
                          </span>
                        </h3>
                      </li>
                      <li>
                        <h3>
                          <span>Plate Category:</span>
                          <label>
                            {customer.order_category === "new-styles" ||
                            customer.order_category === "new-style"
                              ? "New Style"
                              : customer.order_category === "prefix"
                              ? "Prefix"
                              : "Dateless"}
                          </label>
                        </h3>
                      </li>

                      <li>
                        <h3>
                          <span>Order Type:</span>
                          {customer.order_type != 0
                            ? "Fast Track Services"
                            : "Normal"}
                        </h3>
                      </li>

                      <li>
                        <h3>
                          <span>VAT:</span>
                          <label>
                            £{parseFloat(customer.order_vat).toFixed(2)}
                          </label>
                        </h3>
                      </li>
                      <li>
                        <h3>
                          <span>Total:</span>£{customer.order_total}
                        </h3>
                      </li>
                    </ul>
                  </div>
                </div>

                {customer?.payment_status &&
                customer?.payment_mode == "stripe" ? (
                  <div className="col-md-6">
                    <div className="listing-name">
                      <ul>
                        <>
                          {customer.payment_status ? (
                            <li>
                              <h3>
                                <span>Transaction ID:</span>
                                {customer.order_transaction_id}
                              </h3>
                            </li>
                          ) : null}
                          <li>
                            <h3>
                              <span>Payment Status:</span>
                              {customer.payment_status}
                            </h3>
                          </li>
                          <li>
                            <h3>
                              <span>DVLA Fees:</span>£
                              {parseFloat(customer.order_dvlafee).toFixed(2)}
                            </h3>
                          </li>
                          <li>
                            <h3>
                              <span>Coupon:</span>
                              {customer.order_coupon
                                ? customer.order_coupon
                                : "Not Applied"}
                            </h3>
                          </li>
                          <li>
                            <h3>
                              <span>Coupon Price Off:</span>£
                              {customer.order_discount}
                            </h3>
                          </li>
                          {customer.plate_type ? (
                            <li>
                              <h3>
                                <span>Plate Type:</span>
                                {customer.plate_type}
                              </h3>
                            </li>
                          ) : null}
                        </>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-12">
                    <div className="listing-name">
                      <ul>
                        <>
                          <li>
                            <h3>
                              <span>Payment Mode:</span>
                              {customer?.payment_mode}
                            </h3>
                          </li>
                          <li>
                            <h3>
                              <span>Order ID:</span>
                              {buylineDetails?.order_id}
                            </h3>
                          </li>
                          <li>
                            <h3>
                              <span>Checkout ID:</span>
                              {buylineDetails?.checkout_uuid}
                            </h3>
                          </li>
                          <li>
                            <h3>
                              <span>Buyline Status:</span>
                              {buylineDetails?.status}
                            </h3>
                          </li>
                          <li>
                            <h3>
                              <span>DVLA Fees:</span>£
                              {parseFloat(customer?.order_dvlafee).toFixed(2)}
                            </h3>
                          </li>
                          <li>
                            <h3>
                              <span>Coupon:</span>
                              {customer.order_coupon
                                ? customer.order_coupon
                                : "Not Applied"}
                            </h3>
                          </li>
                          <li>
                            <h3>
                              <span>Coupon Price Off:</span>£
                              {customer.order_discount}
                            </h3>
                          </li>
                          {customer.plate_type ? (
                            <li>
                              <h3>
                                <span>Plate Type:</span>
                                {customer.plate_type}
                              </h3>
                            </li>
                          ) : null}
                        </>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}

export default Customer;
