import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "../utils/api";
import moment from "moment";
import Swal from "sweetalert2";

function EditCoupon() {
  const [selectedDate, setSelectedDate] = useState(null); // State to store the selected date
  const [name, setName] = useState("");
  const [percentage, setPercentage] = useState("");
  const [validTill, setValidTill] = useState(new Date());
  const [total, setTotal] = useState("");
  const [couponFor, setCouponFor] = useState("");
  const [c_id, setC_id] = useState("");
  const { coupon } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getCoupon();
  }, [coupon]);

  async function getCoupon() {
    try {
      let res = await apiRequest.get(`/coupon/single/${coupon}`);

      const { message, status } = res.data;
      if (status == 200) {
        setName(message?.c_name);
        setPercentage(message?.c_percentage);
        setValidTill(new Date(parseInt(message?.valid_till)));
        setTotal(message?.total_usage);
        setCouponFor(message?.coupon_for);
        setC_id(message?.c_id);
      } else {
        navigate("/coupons");
      }
    } catch (error) {
      navigate("/coupons");
    }
  }

  async function updateCoupon(e) {
    e.preventDefault();
    try {
      const payLoad = {
        c_name: name,
        c_percentage: percentage,
        total_usage: total,
        valid_till: validTill,
        coupon_for: couponFor,
      };

      let res = await apiRequest.put(`/coupon/update/${c_id}`, payLoad);
      if (res.data.status == 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/coupons");
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  return (
    <>
      <div className="main-content">
        <div className="row">
          <div className="col-md-12">
            <div className="table-title">
              <h2>Edit Coupon</h2>
            </div>
          </div>
        </div>
        <div className="update-form">
          <div className="plate-form edit-form">
            <form onSubmit={updateCoupon}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Name<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Percentage<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      onChange={(e) => setPercentage(e.target.value)}
                      value={percentage}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Valid Till<sup>*</sup>
                    </label>
                    <br />
                    <DatePicker
                      className="form-control"
                      selected={validTill}
                      dateFormat="yyyy-MM-dd"
                      onChange={(d) => setValidTill(d)} // Handle date change
                      // Optional: customize the format
                      // Optional: placeholder text
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Total Number Of Coupons<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      onChange={(e) => setTotal(e.target.value)}
                      value={total}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Coupon For<sup>*</sup>
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setCouponFor(e.target.value)}
                      // defaultValue={couponFor}
                    >
                      <option selected={couponFor == "plate"} value="plate">
                        Private Plate Discount
                      </option>
                      <option selected={couponFor == "service"} value="service">
                        Physical Plate Discount
                      </option>
                      <option selected={couponFor == "fast"} value="fast">
                        Transfer service Discount
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="update-button">
                    <button type="submit" className="btn btn-primary">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCoupon;
